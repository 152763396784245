(function($) {

  // init main menu
  var $other_cases = $('#opdrachten .other');
  // $mainmenu.addClass('collapsed');

  // Main navigation Hamburger toggler
  $('.case-toggler').click(function(e){
    e.preventDefault();

    $(this).toggleClass('open');

    if ( $other_cases.hasClass('expanded') ) {
      $other_cases.removeClass('expanded');
      $other_cases.addClass('collapsed');
    } else {
      $other_cases.addClass('expanded');
      $other_cases.removeClass('collapsed');
    }
  });
})(jQuery);
